import React from "react";
import { GroupList } from "../../modules/kit";
import data from "../../../data/kits";

const Home = () => {
  return (
    <>
      <span>
      <label>Email Me: </label>
      <a href="mailto:cadenmfink@gmail.com" className="email">Cadenmfink@gmail.com</a>
      </span>
      <GroupList groups={data} />
    </>
  );
};

export default Home;
