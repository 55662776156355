import React, { useState } from "react";
import Item from "./Item";
import { toast } from "react-toastify";
import { copy, copyOrange } from "../../../assets/images/icons/svg";

function ItemList({ group }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [copyButtonColor, setCopyButtonColor] = useState("default");

  const price = "$" + group.price;

  const copyPrice = () => {
    navigator.clipboard.writeText(price);
    toast.success("Copied Price!", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="kit">
      <div className="item-list">
        <h2>{group.group}</h2>
        {group.price && (
          <h3>
            {price}{" "}
            <button
              className="copy-price"
              onMouseOver={() => {
                setCopyButtonColor("orange");
              }}
              onMouseOut={() => {
                setCopyButtonColor("default");
              }}
              onClick={() => copyPrice()}
            >
              {copyButtonColor === "orange" ? (
                <img src={copyOrange} alt="Copy" />
              ) : (
                <img src={copy} alt="Copy" />
              )}
            </button>
          </h3>
        )}
        <button className="col-ex" onClick={toggleExpansion}>
          {isExpanded ? "Hide Items" : "View Items"}
        </button>
        {isExpanded && (
          <ul>
            {group.items.map((item, index) => (
              <Item key={index} item={item} />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default ItemList;
