import React from "react";
import { ToastContainer } from "react-toastify";

import "./styles/global/global.scss";
import "react-toastify/dist/ReactToastify.css";
import { Layout, LoaderFormatted } from "./components/modules/global";
import { Route, Routes } from "react-router-dom";
import Home from "./components/pages/home/Home";

function App() {
  return (
    <>
      <React.Suspense fallback={<LoaderFormatted />}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="dev">
              <Route path="modules">
                <Route path="loader" element={<LoaderFormatted />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </React.Suspense>
    </>
  );
}

export default App;
