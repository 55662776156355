import React from "react";

function Item({ item }) {
  return (
    <li className="item">
      <a href={item.link} target="_blank" rel="noreferrer">
        {item.name}
      </a>
    </li>
  );
}

export default Item;
