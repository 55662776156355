import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ItemList from "./ItemList";

function GroupList({ groups }) {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const initialOrderNumber = queryParams.get("build") || "";
  const initialGroupQuery = queryParams.get("group") || "";
  const initialSortOrder = queryParams.get("sort") || "highToLow";

  const [orderNumberQuery, setOrderNumberQuery] = useState(initialOrderNumber);
  const [groupQuery, setGroupQuery] = useState(initialGroupQuery);
  const [sortOrder, setSortOrder] = useState(initialSortOrder);

  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    if (orderNumberQuery) newSearchParams.set("build", orderNumberQuery);
    if (groupQuery) newSearchParams.set("group", groupQuery);
    newSearchParams.set("sort", sortOrder);

    navigate({
      pathname: location.pathname,
      search: newSearchParams.toString(),
    });
  }, [orderNumberQuery, groupQuery, sortOrder, location.pathname, navigate]);

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const filteredGroups = groups.filter(
    (group) =>
      (orderNumberQuery.trim() === "" ||
        group.build.toLowerCase() === orderNumberQuery.toLowerCase()) &&
      group.group.toLowerCase().includes(groupQuery.toLowerCase())
  );

  const sortedGroups = [...filteredGroups].sort((a, b) =>
    sortOrder === "lowToHigh" ? a.price - b.price : b.price - a.price
  );

  return (
    <div className="group-list">
      <div className="search-bar">
        <input
          name="title-search"
          type="text"
          placeholder="Search by Title"
          value={groupQuery}
          onChange={(e) => setGroupQuery(e.target.value)}
        />
        <input
          name="build-search"
          type="number"
          placeholder="Search by Build Number"
          value={orderNumberQuery}
          onChange={(e) => setOrderNumberQuery(e.target.value)}
        />
        <select onChange={handleSortChange} value={sortOrder}>
          <option value="lowToHigh">Low to High</option>
          <option value="highToLow">High to Low</option>
        </select>
      </div>
      {sortedGroups.map((group, index) => (
        <div key={index}>
          <div className="group-header">
            <h3>{group.name}</h3>
          </div>
          <ItemList group={group} />
        </div>
      ))}
    </div>
  );
}

export default GroupList;
