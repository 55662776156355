const data = [
  {
    group: "Multicam Call of Duty Ghost Kit - Custom Build Service",
    price: "289",
    build: "1",
    items: [
      {
        name: "KRYDEX Tactical Belt",
        link: "https://ebay.com/itm/285145079142?hash=item4263f89d66:g:iaIAAOSwDSBgUyX9",
      },
      {
        name: "Condor Gunner Plate Carrier",
        link: "https://www.evike.com/products/49034/",
      },
      {
        name: "Headset Rail Adapter",
        link: "https://www.temu.com/tactical-headset-rail-adapter-headset-holders-headphone-mount-stand-for-19-21mm-helmet-rail-helmet-airsoft-military-earmuff-hold-g-601099524285352.html?top_gallery_url=https%3A%2F%2Fimg.kwcdn.com%2Fproduct%2Fopen%2F2023-08-23%2F1692803160695-14497446abd94e168cb3c437f530043c-goods.jpeg&spec_gallery_id=2030935987&refer_page_sn=10009&refer_source=0&freesia_scene=2&_oak_freesia_scene=2&_oak_rec_ext_1=ODM3&search_key=Tactical%20headset&refer_page_el_sn=200049&_x_sessn_id=f8sbtlyriq&refer_page_name=search_result&refer_page_id=10009_1696897329326_dchezdjq24&no_cache_id=xqt8x",
      },
      {
        name: "9MM/45APS Hardshell Magazine Holder",
        link: "https://www.temu.com/mag-pouch-magazine-holster-for-9mm-45aps-with-molle-clips-g-601099526372346.html?top_gallery_url=https%3A%2F%2Fimg.kwcdn.com%2Fproduct%2FFancyalgo%2FVirtualModelMatting%2Fe4368b3e2a46e6079a885098c42d388c.jpg&spec_gallery_id=2030370483&refer_page_sn=10009&refer_source=0&freesia_scene=2&_oak_freesia_scene=2&_oak_rec_ext_1=MTA0Nw&search_key=hardshell%20magazine%20multicam%20pistol&refer_page_el_sn=200049&refer_page_name=search_result&refer_page_id=10009_1696894964367_bfhxlvzanv&_x_sessn_id=f8sbtlyriq&no_cache_id=qonma",
      },
      {
        name: "Drop Leg Holster",
        link: "https://www.temu.com/universal-multifunctional-drop-leg-holster-adjustable-thigh-holster-g-601099516731665.html?top_gallery_url=https%3A%2F%2Fimg.kwcdn.com%2Fproduct%2FFancyalgo%2FVirtualModelMatting%2Ff75de88caa0c84c8a0ed66c7146373c1.jpg&spec_gallery_id=2034372515&refer_page_sn=10009&refer_source=0&freesia_scene=2&_oak_freesia_scene=2&_oak_rec_ext_1=OTQ4&search_key=dropleg%20holster%20hard&refer_page_el_sn=200049&refer_page_name=search_result&refer_page_id=10009_1696894964367_bfhxlvzanv&_x_sessn_id=f8sbtlyriq&no_cache_id=5vs11",
      },
      {
        name: "2pcs Red Glow Stick",
        link: "https://www.temu.com/2pcs-magical-disposable-glow-stick-portable-first-aid-stick-for-outdoor-camping-hiking-emergency-g-601099524323911.html?top_gallery_url=https%3A%2F%2Fimg.kwcdn.com%2Fproduct%2Fopen%2F2023-08-24%2F1692857575117-8de57f8030924508ac92c6360e596ad2-goods.jpeg&sku_id=17592250489087&refer_page_sn=10009&refer_source=0&freesia_scene=2&_oak_freesia_scene=2&_oak_rec_ext_1=MjQ4&search_key=glowstick%20red&refer_page_el_sn=200049&refer_page_name=search_result&refer_page_id=10009_1696896459369_5idzlbib3p&_x_sessn_id=f8sbtlyriq&no_cache_id=d60lf",
      },
      {
        name: "Tourniquet Storage Pouch",
        link: "https://www.temu.com/mini-leisure-multifunctional-storage-bag-tourniquet-storage-bag-tactical-medical-bag-military-outdoor-multifunctional-small-bag-for-laser-flashlight-g-601099522063198.html?no_cache_id=0tzcb",
      },
      {
        name: "First Aid Kit",
        link: "https://www.temu.com/outdoor-tactical-storage-bag-medical-bag-portable-survival-kits-first-aid-kits-g-601099519896115.html?top_gallery_url=https%3A%2F%2Fimg.kwcdn.com%2Fproduct%2FFancyalgo%2FVirtualModelMatting%2Fcffd48533641cac27fb32a273c4b2f1b.jpg&spec_gallery_id=2011040047&refer_page_sn=10009&refer_source=0&freesia_scene=2&_oak_freesia_scene=2&_oak_rec_ext_1=NTU4&search_key=ifak&refer_page_el_sn=200049&refer_page_name=search_result&refer_page_id=10009_1696896459369_5idzlbib3p&_x_sessn_id=f8sbtlyriq&no_cache_id=mno4s",
      },
      {
        name: "Toolkit Molle",
        link: "https://www.temu.com/600d-outdoor-mountaineering-hiking-edc-toolkit-molle-camo-waist-pack-multifunctional-phone-bag-commuter-bag-g-601099526114106.html?top_gallery_url=https%3A%2F%2Fimg.kwcdn.com%2Fproduct%2FFancyalgo%2FVirtualModelMatting%2F5967dcbb52f89c8b2afde2ac4db45069.jpg&spec_gallery_id=2030205187&refer_page_sn=10009&refer_source=0&freesia_scene=2&_oak_freesia_scene=2&_oak_rec_ext_1=Mzk3&search_key=square%20pouch%20molle&refer_page_el_sn=200049&refer_page_name=search_result&refer_page_id=10009_1696896459369_5idzlbib3p&_x_sessn_id=f8sbtlyriq&no_cache_id=vdy6a",
      },
      {
        name: "Radio Pouch",
        link: "https://www.temu.com/1pc-tactical-pouch-with-molle-system-portable-radio-walkie-talkie-waist-bag-pocket-interphone-holster-carry-bag-g-601099515718780.html?top_gallery_url=https%3A%2F%2Fimg.kwcdn.com%2Fproduct%2FFancyalgo%2FVirtualModelMatting%2F5a20fd30f3f7a5d8cfc40f74fd9ea237.jpg&spec_gallery_id=20263227&refer_page_sn=10009&refer_source=0&freesia_scene=2&_oak_freesia_scene=2&_oak_rec_ext_1=MzA4&search_key=radio%20pouch%20multicam&refer_page_el_sn=200049&refer_page_name=search_result&refer_page_id=10009_1696896459369_5idzlbib3p&_x_sessn_id=f8sbtlyriq&is_back=1&no_cache_id=98tl4",
      },
      {
        name: "Fast Bump Helmet",
        link: "https://www.temu.com/abs-outdoor-sport-cs-game-personal-protective-safety-helmet-fast-mh-tactical-motor-bike-helmet-g-601099516736075.html?top_gallery_url=https%3A%2F%2Fimg.kwcdn.com%2Fproduct%2FFancyalgo%2FVirtualModelMatting%2F32eed19002b8000c679a3f747f0ebb32.jpg&spec_gallery_id=23830019&refer_page_sn=10009&refer_source=0&freesia_scene=2&_oak_freesia_scene=2&_oak_rec_ext_1=MjM3OA&search_key=bump%20helmet&refer_page_el_sn=200049&_x_sessn_id=f8sbtlyriq&refer_page_name=search_result&refer_page_id=10009_1696897243691_ys2vyvdpdi&no_cache_id=wakyv",
      },
      {
        name: "Electronic Shooting Earmuff",
        link: "https://www.temu.com/tactical-electronic-shooting-earmuff-outdoor-sports-anti-noise-headset-for-noise-reduction-sound-amplification-g-601099516755906.html?no_cache_id=lay3c",
      },
      {
        name: "Ghost(COD) Skull Mask",
        link: "https://www.temu.com/call-of-duty-ghost-mask-skull-full-face-mask-mw2-cosplay-costume-mask-for-halloween-cosplay-for-men-g-601099514487793.html?top_gallery_url=https%3A%2F%2Fimg.kwcdn.com%2Fproduct%2FFancyalgo%2FVirtualModelMatting%2F48840c0af3358db271b01f2e1ce0d5db.jpg&spec_gallery_id=21434398&refer_page_sn=10009&refer_source=0&freesia_scene=2&_oak_freesia_scene=2&_oak_rec_ext_1=MTA0OA&search_key=ghost%20mask&refer_page_el_sn=200049&refer_page_name=search_result&refer_page_id=10009_1696896459369_5idzlbib3p&_x_sessn_id=f8sbtlyriq&no_cache_id=2lj17",
      },
      {
        name: "Skeleton Gloves",
        link: "https://www.temu.com/outdoor-motorcycle-bicycle-riding-gloves-autumn-and-winter-ghost-claws-windproof-coldproof-gloves-g-601099524783067.html?rps=10032&r_idx=1&r_pid=601099514487793&no_cache_id=s1xv8",
      },
      {
        name: "Knee Pads",
        link: "https://www.temu.com/military-fans-sports-kneepad-elbow-protection-suit-outdoor-kneepad-crawling-training-riding-camouflage-tactical-kneepad-for-weight-40-110kg-g-601099514950859.html?no_cache_id=izri1",
      },
      {
        name: "British Union Jack Flag Patch",
        link: "https://www.temu.com/tactical-british-union-jack-flag-patch-embroidered-applique-uk-great-britain-iron-on-sew-on-emblem-g-601099515203022.html?top_gallery_url=https%3A%2F%2Fimg.kwcdn.com%2Fproduct%2FFancyalgo%2FVirtualModelMatting%2F7c97a071185cd519831bf0eb413a2f74.jpg&spec_gallery_id=23741807&refer_page_sn=10009&refer_source=0&freesia_scene=2&_oak_freesia_scene=2&_oak_rec_ext_1=MTQ4&search_key=british%20flag%20patch&refer_page_el_sn=200049&refer_page_name=search_result&refer_page_id=10009_1696896459369_5idzlbib3p&_x_sessn_id=f8sbtlyriq&no_cache_id=v54jz",
      },
      {
        name: "EDC Tool Pouch",
        link: "https://www.temu.com/molle-pouch-military-tactical-waist-bag-edc-tools-zipper-waist-belt-pack-phone-case-pocket-airsoft-phone-army-molle-hunting-bag-g-601099518573131.html",
      },
      {
        name: "Helmet Battery Pouch",
        link: "https://www.temu.com/tactical-helmet-battery-pouch-mk2-helmet-battery-pack-balance-bag-for-hunting-outdoor-sports-g-601099518391867.html?no_cache_id=xbhes",
      },
      //{ name: "", link: "" }
    ],
  },
  {
    group: "Black Tactical Belt v1 - Custom Build Service",
    price: "180.59",
    build: "2",
    items: [
      {
        name: "Molle Battle Belt",
        link: "https://www.amazon.com/TACNEX-Tactical-Riggers-Military-Utility/dp/B09WLLRGHM/ref=sr_1_12?hvadid=664415958865&hvdev=c&hvlocphy=9003289&hvnetw=g&hvqmt=e&hvrand=11537973628870937197&hvtargid=kwd-453677167618&hydadcr=9684_13688391&keywords=tactical%2Butility%2Bbelts&qid=1697143926&sr=8-12&th=1",
      },
      {
        name: "Tourniquet Pouch",
        link: "https://www.amazon.com/dp/B0BJKKQ1FH/ref=twister_B0BJKJZFY4?_encoding=UTF8&th=1",
      },
      {
        name: "First-aid Belt Pouch",
        link: "https://www.amazon.com/dp/B0BBG4GBJ5/ref=twister_B0BBG2KCFW?_encoding=UTF8&th=1",
      },
      {
        name: "2pc Molle Pistol Mag Pouch",
        link: "https://www.amazon.com/Pistol-Single-Magazine-Calibers-Springfield/dp/B0BXSV7H2M/ref=sr_1_6?crid=MD2T05BS13E8&keywords=pistol+mag+pouches&qid=1697143963&sprefix=pistol+mag+pouches%2Caps%2C92&sr=8-6",
      },
      {
        name: "Kydex Holster Custom Fit",
        link: "https://www.amazon.com/Glock-Holster-IWB-Kydex-Fit/dp/B07Y3B9VD3/ref=sr_1_2_sspa?crid=2TG7UO8FJC3IZ&keywords=glock%2Bholster&qid=1697144161&sprefix=glock%2Bholster%2Caps%2C110&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1",
      },
      {
        name: "Tactical Phone Pouch",
        link: "https://www.amazon.com/WYNEX-Tactical-Smartphone-Cellphone-Organizer/dp/B08L31W2ZS/ref=sr_1_45?crid=TDTWI5LHGK76&keywords=utility%2Bpouch&qid=1697144103&sprefix=utility%2Bpouch%2Caps%2C93&sr=8-45&th=1",
      },
      {
        name: "KRYDEX Molle Dump Pouch",
        link: "https://www.amazon.com/dp/B096R65LLS/ref=twister_B096R7QL6T?_encoding=UTF8&th=1",
      },
    ],
  },
  {
    group: "Black Tactical Belt v2 - Custom Build Service",
    price: "278.60",
    build: "2",
    items: [
      {
        name: "MAVERICK BATTLE BELT",
        link: "https://www.511tactical.com/maverick-battle-belt.html",
      },
      {
        name: "Tourniquet Pouch",
        link: "https://www.amazon.com/dp/B0BJKKQ1FH/ref=twister_B0BJKJZFY4?_encoding=UTF8&th=1",
      },
      {
        name: "First-aid Belt Pouch",
        link: "https://www.amazon.com/dp/B0BBG4GBJ5/ref=twister_B0BBG2KCFW?_encoding=UTF8&th=1",
      },
      {
        name: "2pc Molle Pistol Mag Pouch",
        link: "https://www.amazon.com/Pistol-Single-Magazine-Calibers-Springfield/dp/B0BXSV7H2M/ref=sr_1_6?crid=MD2T05BS13E8&keywords=pistol+mag+pouches&qid=1697143963&sprefix=pistol+mag+pouches%2Caps%2C92&sr=8-6",
      },
      {
        name: "Kydex Holster Custom Fit",
        link: "https://www.amazon.com/Glock-Holster-IWB-Kydex-Fit/dp/B07Y3B9VD3/ref=sr_1_2_sspa?crid=2TG7UO8FJC3IZ&keywords=glock%2Bholster&qid=1697144161&sprefix=glock%2Bholster%2Caps%2C110&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1",
      },
      {
        name: "Tactical Phone Pouch",
        link: "https://www.amazon.com/WYNEX-Tactical-Smartphone-Cellphone-Organizer/dp/B08L31W2ZS/ref=sr_1_45?crid=TDTWI5LHGK76&keywords=utility%2Bpouch&qid=1697144103&sprefix=utility%2Bpouch%2Caps%2C93&sr=8-45&th=1",
      },
      {
        name: "KRYDEX Molle Dump Pouch",
        link: "https://www.amazon.com/dp/B096R65LLS/ref=twister_B096R7QL6T?_encoding=UTF8&th=1",
      },
    ],
  },
  {
    group: "Milsim Kit, Woodland and Tan - Custom Build Service",
    price: "222.18",
    build: "3",
    items: [
      { name: "Woodland Combat Shirt", link: "https://www.ebay.com/itm/163773561772?hash=item2621aa3bac:g:bAgAAOSwr6FaKcFb&amdata=enc%3AAQAIAAAA0EXLOAgr6AcvBSkSxLCsZKkoGNJTS2WIuAUCscmu%2Fy739dt8FuLO4MtA%2FAb2oyWQuXUmoh%2FtiZmI0K420%2F0D3g9cvj%2B%2FibEE1qTElbqFXiGFVZH4%2FQmkNY8E1jiDJMI0rhjy15HIUTyOM%2BG1gxbV0%2BztTTdXOvov7vtlofD72KDdfkp%2FRB0gPdqBBMYPmpJJpi8mfTIiIyIjhE1AH8sLp1Dy4HvJLWhNK9i4DXdMtOJ%2F04IJRh%2Bsp%2BbdRAh7e5obrD0gH0ppd%2F1Wqn9v%2B8BaClw%3D%7Ctkp%3ABk9SR8SM79n4Yg" },
      { name: "Tan Tactical Belt", link: "https://www.ebay.com/itm/284323702932?hash=item4233036894:g:hiAAAOSwyPhfyLHD" },
      { name: "First Aid Kit Pouch MOLLE", link: "https://www.ebay.com/itm/284045897847?hash=item4222747077:g:M9UAAOSwp~1hMJP1" },
      { name: "KRYDEX Tactical Sling 2 / 1 Point", link: "https://www.ebay.com/itm/284538554354?hash=item423fd1c7f2:g:cwUAAOSwoW5kBvJh" },
      { name: "KRYDEX Mini Dangler Drop Pouch", link: "https://www.ebay.com/itm/284468364592?hash=item423ba2c530:g:iZ4AAOSwxuJhUuhf&amdata=enc%3AAQAIAAAA4N1Xwh0n%2BiC1m7baZr0Guk5r4Y9zl6oIhxWJ%2FSEwKjm1praAWzAL8lxMR2MZRHy3%2FkFOj26I3LIfubTcqe9o1GmQMclyNMUwxayQrCix%2FZAipmQm05XAx44YHKlse8Tza9mDm%2FQXZRw%2BNkddm%2FbBD9FoS4icTdLVd2kGgqYkh91TvO07jbVYrU%2FZOscaYpqS2dEwEdjD%2BSVMq%2Bphor7nXInxmAZuZ1YOOJu%2BIRu9e6ClI1EDH8ljopKycM6VTEPJ6c9u5aKwBK48hp2KJbgNQnfvtjYBV9kPooQLFgIj0G6G%7Ctkp%3ABFBM5ve22vhi" },
      { name: "Dump Pouch", link: "https://us.novritsch.com/product/dump-pouch/" },
      { name: "Helmet Cover", link: "https://www.ebay.com/itm/354295322381?hash=item527da5970d:g:W4IAAOSwZ9tg4tZR&amdata=enc%3AAQAIAAAA4Hs60qmPYzH2Bka4JwVR6A5Wh9n0fLvY%2Bv4sD3dgA6TOX0k4hmJ5KJ5QZb4k0PUKY96Tef8UHw2hHsEqQIqv9wWN1LdgpMeWzLL8yxBVZaYbA8bBJW7tbJ0MbhBy1UNwoc7QT9x%2BgRLCSApZepDqJUbCsGQ9%2BR8%2Fod3O%2B3wRdpp88OXoTF7ec8KJjrsGQn1V5x%2FzmoQlrhOMTMkwKfoTIpbYWckGFgn0z6pwrqgqeN2A0iyhzn7Zmi44JfEFC0EBYAQikd0CN8iQq1FpGvJ5nZ4xYT5j0t4W0eOIhmlPxIxt%7Ctkp%3ABFBMpIfS2vhi" },
      { name: "Camo Scrims", link: "https://www.ebay.com/itm/314908581768?hash=item4952039f88:g:6YcAAOSwQTJlRG6u&amdata=enc%3AAQAIAAAA4AXqq9%2Bh4vMCR7H8V7%2F%2F%2BZnyT4pIusSEBTASO2efnrcpYJnSvzjdb1BxuoFgVJTBym%2Bt7E46rgBLc64jNqHHvtaoON5uoPf2ZVTq4Qqaob3UdeZAMv0LXSwVVKLSmX1AzFnh%2BuD4WYS49SmgVHAgrwbkk0d5JveZCCjLK2BUaWbfOvVcx1Wk%2FJUXV%2BLl1z%2FK8ntBOlytUn%2BaNCBssdMf4jLeM%2BmmY%2BZiHcRzmAmhB49vtApxmyywK0r0kY0n%2B6cUfdCTbNfx0Ey9sFZg7ZBmuAyT9w7CRdIqunA71s1H5trp%7Ctkp%3ABFBM5pLp2vhi" },
      { name: "Woodland Ripstop Cargo Work Pant", link: "https://www.ebay.com/itm/174607757868?hash=item28a76ef22c:g:ud0AAOSwyetlOH5h&amdata=enc%3AAQAIAAAA4EmfYPBdhwbU%2Fda2MVCLvq9haQRbHZiwMht2ulIx1HqT55HXBWZ0CNfdGpNAaYdjWivbxjHPi%2FIwVlMcl%2FIMqXRmvlgzoh%2F7N7zBaUhwGCuJ%2F6GIy48g63tn4o7jmAbPGYybCH5TjptAmId9XSJPQrWSzPpq%2FnWKmOYXsu%2BwuV9ZxvtCTptnK58odh2OY34BwobDU4iTOS0iwiRwnO%2FYBj75wmAsCnbZOMkleVSvbcWps%2F7YNHsbB22UXVba0Xfx8Du20FSTC%2BIf1UOTLcTz8YuHnazIw59CcOfXOI80zTxd%7Ctkp%3ABFBM6I782vhi" },
      { name: "5.56/7.62mm Molle Mag Holder", link: "https://www.ebay.com/itm/354954886415?var=624240075257&hash=item52a4f5bd0f:g:mLsAAOSwLjtiV9mu&amdata=enc%3AAQAIAAAA8OHvFZW32teE%2FIfBTbNklOmii97BOba7bEr5esF8qNg7K5G1JXXTkK%2FXDMo3bE76srI1P2AxwtUmhLkwGX0L7%2FgDl2Rehi2Pk%2BrzcyitYsfeOtx7js1Z56X%2F4T4I7aV34FdB4pa%2FLJGvX12Q%2F2xQHbDMg0vGHhCgPszLSxdk6RipHPcvAycZgOP%2FdTrPL6M0a83fhPcfd9Wl%2BONKsGKiBxVyPAIx5dp3Q%2B7QhcT%2BugGiasDCbpEpPGrwHjuWpf1TXMTpHY74W6N7KllepNaQ2DDBlA1uMTfNGDQJnW096C45rUSjgqJ9SgQrj6vVXtHh1A%3D%3D%7Ctkp%3ABFBMqMON2_hi" },
    ],
  },
  {
    group: "Ukrainian Rifleman full camo - Custom Build Service",
    price: "814",
    build: "4",
    items: [
      { name: "ACH Helmet", link: "https://www.hopupairsoft.com/posts/-LOKtmKmx3LVmmF5G_YO" },
      { name: "Rothco Soft Shell Woodland Jacket", link: "https://www.amazon.com/Rothco-Special-Jacket-Woodland-X-Large/dp/B00F1ZM54S/ref=sr_1_3?keywords=woodland+camo+jacket&qid=1700051493&s=apparel&sr=1-3" },
      { name: "Crye Precision Plate Carrier, Ranger Green", link: "https://www.cryeprecision.com/JPC-2-0?quantity=1&size=Medium&color=Ranger%2520Green" },
      { name: "Night Vision Mount", link: "https://www.amazon.com/Night-Vision-Googgles-Helmet-Tactical/dp/B08TBSBY51/ref=sr_1_10?keywords=nvg+bracket&qid=1700052278&sr=8-10" },
      { name: "Face Cover", link: "https://www.amazon.com/XY3545HS-Balaclava-Women-Hunting%EF%BC%8CSkiing%EF%BC%8CMotorcycle-Windproof%EF%BC%8CUV-Protection/dp/B0CBM9P2XL/ref=sr_1_5?crid=2LRW3OHE360DG&keywords=m81+face+mask&qid=1700052538&sprefix=m81+face+mask%2Caps%2C196&sr=8-5" },
      { name: "American Flag Patch x2", link: "https://www.amazon.com/American-Military-Tactical-Morale-Backing/dp/B07F7S5WTH/ref=sr_1_1_sspa?crid=GDKFHV41O99E&keywords=american+flag+patch+green&qid=1700052626&sprefix=american+flag+patch+green%2Caps%2C143&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1" },
      { name: "Foliage Green Duty Belt", link: "https://www.ebay.com/itm/254679664868?hash=item3b4c1748e4:g:lxMAAOSwiXZfLQpa&amdata=enc%3AAQAIAAAA4Ci6YP8%2FX2eKUuammW1lSczelWk8kVxF6e9gaPRKk1s4k99A0CbIW3OUO5C5%2BP%2FoDhGd8LeeJwXBh%2BYJaZgrxArL8r%2FdOz%2FaiihnVtB4HNkUYWCTGBewGGWZ%2BlokJUSfWM79aJ4i9q6s4r31wPCA%2BRHxUZxipArIi8AA%2F4WcGOYwtavXYlYAlEoVx224SFFRIlqjXf00hU%2F0CD8NMMZWwXd2noYhVgQ9Q3hA5zYOT8l8%2FvTr5Icwo%2B7aTR0YtMIpYDd%2FUN%2Fn53a3GCKxz99FDvqzvej3sTF828Z1D41JjNEq%7Ctkp%3ABFBMkuPdsPpi" },
      { name: "M81 Rifle Mag Pouch ( for belt )", link: "https://www.evike.com/products/51164/" },
      { name: "M81 Helmet Cover", link: "https://www.amazon.com/Elite-Tribe-Combat-Airsoft-Tactical/dp/B0B1MF2FHY/ref=sr_1_6?keywords=woodland%2Bhelmet%2Bcover&qid=1700053734&sr=8-6&th=1" },
      { name: "M81 Dump Pouch", link: "https://www.amazon.com/bullet-pouch-Molle-woodland/dp/B002YAI47K" },
      { name: "DPM Mystery Ranch Tri-Zip Backpack", link: "https://www.amazon.com/dp/B096YH68RH/ref=twister_B07TCJG254?th=1" },
      // { name: "", link: "" },
    ]
  },
  {
    group: "US Military AOR1 Basic Bundle",
    price: " - Pending",
    build: "5",
    items: [
      { name: "Mystery Ranch 2 Day Backpack", link: "https://www.amazon.com/MYSTERY-RANCH-Day-Assault-Backpack/dp/B07TCJFSRG/ref=sr_1_8?crid=1JFLX0AR2P5B7&keywords=m81%2Bbackpack&qid=1700057293&s=sporting-goods&sprefix=m81%2Bbackpack%2Csporting%2C130&sr=1-8&th=1" },
      { name: "AOR1 Emerson g3 pants", link: "https://www.amazon.com/EMERSONGEAR-Tactical-Combat-Ripstop-Military/dp/B0BPCG1JJY/ref=sr_1_1?keywords=aor1+pants&qid=1700057910&sr=8-1" },
      { name: "AOR1 Combat Shirt", link: "https://www.amazon.com/CQR-Combat-Tactical-Assault-Military/dp/B097CZXR58/ref=sr_1_6?keywords=aor1+shirt&qid=1700058024&sr=8-6" },
      { name: "Tan Plate Carrier", link: "https://www.ebay.com/itm/234813814467?hash=item36abfe76c3:g:4LwAAOSwQWFjk3OC&amdata=enc%3AAQAIAAAA4OxWaGY7zh%2FyaL%2FU1IsZ4m2PJclTmrhpYnmoFiWVsM00Mehmon7o08LrtUwLK6k2SftfrXwSlpnnNXVdhn%2F%2BJmbSZSAqXrLSOtqU6WI6s1uGx05geIfJggJb%2Fkayxfsc7zHcWIUQZnbPoTQNPjBdsG9FMI8FMc9q0tw2GcOt%2FL6gS1gL0w9zp4SHpo3syo2wAwVk8L%2FRykqCNnSLXHtm42yN9kng%2FqlwIDQ0XrbiarOM7k4dyqDs5kkylk0KXF04%2FZT%2F7AWHcjmYM6rr5xgt%2BXZpxGYare3siG8LICz6wF83%7Ctkp%3ABFBM2Jb_ufpi" },
      { name: "Gentex ACH Helmet", link: "https://www.ebay.com/itm/145433937658?hash=item21dc89c6fa:g:C2gAAOSwKXxlUsob&amdata=enc%3AAQAIAAAAsEpioM%2Fb84BuWoFUHgInzsdIsKQvfhODwW4v5w4E%2FKzcBBhlajhHGkShK3iDKf7COi7H3EqmOm0tCt3sJn98dPjyV%2FhLo%2BYK%2FFJweYbub%2BHhc5uYfbxsRUSmpcWyKNqaWb%2FSQMel2%2FD6nylEEf6vNBDGLsW9sIMg%2FtrNoR1IB6Bfe6gkTRPFp1qoa%2B7II0s9CTnXuwR8Q1ugmvchPOPmJOBltsJ6nbNl1MQtx81SK%2FgV%7Ctkp%3ABk9SR_K28bX6Yg" },
      { name: "AOR1 ACH Helmet Cover", link: "https://www.ebay.com/itm/115653259309?hash=item1aed78b02d:g:ksMAAOSw0Y9jqVtA&amdata=enc%3AAQAIAAAA4Er3t14x9VrRh8%2BPxlxFfAut2VwsJL%2Fy0%2BjLigiCWxh%2BilKECJ8T6NrdPusH3B%2FlQzryauFv2BmCk0ZYBWY1ThKr%2B36RBhU%2BA16zSJUpGq5qtuXKMgZ%2FwcosrvYilQzgy30HIQ2BxE2Trsllzfgp9qqd0bSPk%2BKxE%2FOzTFyHIMCqHYSTSy6LMoC6qdR%2BJ2Y46VzuCFKL%2BmC%2BoBWmBp83iQ%2FH9RuWf6TWqO0PNqBOfuWi4q4BpYEDazIQ5RUKBY665EDi3sX%2BQFzAhlCVunMoMECGW47%2F8veF8jB00woRgzaH%7Ctkp%3ABk9SR_inhbb6Yg" },
      { name: "NVG Bracket ACH", link: "https://www.ebay.com/itm/165986592569?hash=item26a5926739:g:Z6IAAOSwz~NkFJ6e&amdata=enc%3AAQAIAAAA0JmVZrxCeWRt86%2BCS1ZHK4VUDJYTOKhN26P%2FCjylUpGBGLj8twFTf4wznOjwDJ6Sg4HYts573NRiml%2F%2FslD8bwEjazFr5%2BX8wiXqDMlSwQt5yCtdRz11QdPaGchVKz%2BWVwdHhJ9t1GQDQZhqULRUBi8c9VMmeVxBoYWx%2BJ%2BLI4YFi4kzJ6c%2Fx7kbmTz58A8bsGjqfTL7r1D8o3GztojP94RQuDHnsHO1XuNlmc9JJrJ%2B5FaKKfKvjpBG62CKdjRPxSBXQ81iRp8eEZuy3HadSaw%3D%7Ctkp%3ABk9SR-7ekbb6Yg" },
      { name: "Crossbow Sun Glasses", link: "https://www.amazon.com/ESS-Eyewear-Crossbow-Suppressor-Smoke/dp/B01EXK2X5C/ref=asc_df_B01EXK2X5C/?tag=hyprod-20&linkCode=df0&hvadid=459416849886&hvpos=&hvnetw=g&hvrand=3325703965803161319&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9003292&hvtargid=pla-930527792763&psc=1" },
      { name: "AOR1 3 Rifle Mag Pouch", link: "https://www.amazon.com/Elite-Tribe-Airsoft-Magazine-Tactical/dp/B07XKSPP7S/ref=sr_1_12?keywords=aor1%2Bpouch&qid=1700058646&sr=8-12&th=1" },
      { name: "S.T.R.I.K.E Patrol Belt", link: "https://www.blackhawk.com/tactical-nylon/patrol-belts/s.t.r.i.k.e.-padded-patrol-belt/1417.html" },
    ]
  },
  {
    group: "Black Kit - Liam Porter - Custom Build Service",
    price: "139.63",
    build: "6",
    items: [
      { name: "Black Emerson JPC", link: "https://www.ebay.com/itm/353375401705?hash=item5246d0b6e9:g:6rYAAOSwuuFgGm8r&amdata=enc%3AAQAIAAAAwKomxmKQTipGV7ye%2B9BNIkWYevgRucVZfPjJsSDTE4m5lcKcL5K%2F0ZEGMEqlIVjSWeuGuGjHGgK0Hk2cmmz0bBSSp9EWRU5dC0J2Jb4hJhv1C3QPV52396ugr%2BN2P2Ee3ae5k5M6DE3sE6dvd4d2pUoiWoEmPdXQv2Xr0WyulgHDJT7k678K57Gav42uAyZzNCu4Lx%2BnFgx2L40QyjHmGywt92%2B7f84SzzGjHrXX8Mu3pPyUx6ZqqERAs%2BTIe1WCCg%3D%3D%7Ctkp%3ABk9SR_jds7j6Yg" },
      { name: "Black JPC Back Panel", link: "https://www.ebay.com/itm/303693227817?var=602948974461&hash=item46b586e729:g:-50AAOSwoqZfYdob&amdata=enc%3AAQAIAAAA8ClLiUqaz7ObozQEeqnnQ6UMHlLvPyiyftRS7dvx8516YTfHiYMTreJ%2FRb5uUgdYRnH9JHa1Qrhwh0%2BEx42JziO%2Bej6pQDghSkHlRMznGgAWVhCoG1UtYsZyfflJND2QeqYbM1f6PryZZ%2FnGGRxiJrUWKaqI%2FUX75wqmnhI%2BbuD%2FUlFtyAoeg7dncBKP5OSGIl9dgTIYiFkM6u26oMX7TC%2ByfJ4iIomD1DjT4%2BwOBUdwpzw4vmyYCXJq4KHa3Iqurq29MmyOl5YjvWmmvocxBtqr6zCOp5UHIum7dR8cZTbNZlZDoUE5LQFJKFh9fl0oYg%3D%3D%7Ctkp%3ABk9SR_Df5Ln6Yg" },
      { name: "Black Triple Mag Pouch", link: "https://www.ebay.com/itm/313717009207?var=612498975872&hash=item490afdab37:g:DOgAAOSwnVlhbmSd&amdata=enc%3AAQAIAAAA4LPAaPKE%2BZA9%2BKx8UmhVaf%2BQv7QHFA9gq8%2F2KCrebM3AbshJRyKCcXGs5k5giwgIDYgwehd8MTvhk34PfBVYYbCd3ZDuJqfNtPEoLZXcEq8vBsAIDKVoBLbj%2ByO8SXeNAhJEz1pmK6LYvCY19ghTWsbcfqGQoCfj3pWNmOsDbSqhbhsrRu7wmn8FIRNGs2hWADs4sb7ZtDGmuNUW0aofmXlwdIOfM6XqZdwnlcKeBReiBOqFdx4Xk5AlpEpXWmctdYedrsI7klSloUTM42DSPDbjK2KDjn0vUHxT4hYtHey1%7Ctkp%3ABFBMpurZuPpi" },
      { name: "Black Phone Holder", link: "https://www.aliexpress.us/item/3256805752806689.html?spm=a2g0o.detail.0.0.3c60l4mTl4mTsk&gps-id=pcDetailTopMoreOtherSeller&scm=1007.40050.362094.0&scm_id=1007.40050.362094.0&scm-url=1007.40050.362094.0&pvid=31b89156-3984-412a-b46e-6efa940d6038&_t=gps-id:pcDetailTopMoreOtherSeller,scm-url:1007.40050.362094.0,pvid:31b89156-3984-412a-b46e-6efa940d6038,tpp_buckets:668%232846%238109%231935&pdp_npi=4%40dis%21USD%2122.79%217.75%21%21%2122.79%21%21%402101fb1517000614282244051e9a0d%2112000034940026917%21rec%21US%21%21AB" },
    ],
  },
  {
    group: "Black Helmet with Scrims and dummy NVGs - Custom Build Service",
    price: "?",
    build: "7",
    items: [
      { name: "FMA Caiman Helmet, black", link: "https://www.ebay.com/itm/364274346327?mkcid=16&mkevt=1&mkrid=711-127632-2357-0&ssspo=5ktzf79xsbk&sssrc=4429486&ssuid=ymmzstyurlo&var=633788561589&widget_ver=artemis&media=COPY" },
      { name: "Caiman Helmet cover, black", link: "https://www.ebay.com/itm/295193713687?mkcid=16&mkevt=1&mkrid=711-127632-2357-0&ssspo=De7sDrVaRd6&sssrc=4429486&ssuid=ymmzstyurlo&var=593404480177&widget_ver=artemis&media=COPY" },
      { name: "3d Scrims, Multicam Black", link: "https://www.ebay.com/itm/256112522304?mkcid=16&mkevt=1&mkrid=711-127632-2357-0&ssspo=l0wvVlQwSDS&sssrc=4429486&ssuid=ymmzstyurlo&var=556078836850&widget_ver=artemis&media=COPY" },
      { name: "Repro Comtacs", link: "https://www.ebay.com/itm/354306116115" },
      { name: "Helmet Flashlight", link: "https://www.ebay.com/itm/354282870065?mkcid=16&mkevt=1&mkrid=711-127632-2357-0&ssspo=poclwknwqaq&sssrc=4429486&ssuid=ymmzstyurlo&var=&widget_ver=artemis&media=COPY" },
      { name: "Dummy Quad Nods", link: "https://www.ebay.com/itm/364155502446?mkcid=16&mkevt=1&mkrid=711-127632-2357-0&ssspo=5ktzf79xsbk&sssrc=4429486&ssuid=ymmzstyurlo&var=&widget_ver=artemis&media=COPY" },
      { name: "NVG mount", link: "https://a.co/d/1UzoDhQ" },
    ],
  },
  {
    group: "First Spear Multicam Setup, Rifleman",
    price: "9551.28",
    build: "11",
    items: [
      { name: "AAC with Instant Adjust Back Panel and Adaptive Maritime Cummerbund", link: "https://www.first-spear.com/aac-and-overlap-cummerbund/" },
      { name: "Mystery Ranch 6500", link: "https://www.mysteryranch.com/6500-pack?quantity=1&color=Multicam&size=M" },
      { name: "LaserFrame M4/AK Triple Magazine Pocket", link: "https://www.first-spear.com/laserframe-m4-ak-triple-magazine-pocket/" },
      { name: "LaserFrame M4 Flap Single Magazine Pocket", link: "https://www.first-spear.com/laserframe-m4-flap-single-magazine-pocket/" },
      { name: "Ranger Med Pouch", link: "https://www.first-spear.com/ranger-med-pouch/" },
      { name: "Radio Pocket, MBITR", link: "https://www.first-spear.com/radio-pocket-mbitr/" },
      { name: "LaserFrame Fragmentation Grenade Pocket", link: "https://www.first-spear.com/laserframe-fragmentation-grenade-pocket/" },
      { name: "LaserFrame Flashbang Pocket", link: "https://www.first-spear.com/laserframe-flashbang-pocket/" },
      { name: "General Purpose Pocket, Large", link: "https://www.first-spear.com/general-purpose-pocket-large/" },
      { name: "Admin Pocket", link: "https://www.first-spear.com/admin-pocket/" },
      { name: "FZ-N1 ARMOR.MOUNT PLATE CARRIER PALS/MOLLE", link: "https://juggernautcase.com/products/fz-n1-armor-mount-plate-carrier-pals-molle.html" },
      { name: "Select Phone case for your specific phone, needed for FZ-N1", link: "https://juggernautcase.com/categories/shop-products/shop-by-device/apple.html" },
      { name: "Operators Two Point Sling", link: "https://www.first-spear.com/operators-two-point-sling/" },
      { name: "FS / CSM Single Point Sling", link: "https://www.first-spear.com/fs-/-csm-single-point-sling/" },
      { name: "Two-Point Quick Release Sling", link: "https://www.first-spear.com/two-point-quick-release-sling/" },
      { name: "Padded AGB Sleeve 6/12™, Low Profile", link: "https://www.first-spear.com/padded-agb-sleeve-6/12-low-profile/" },
      { name: "Assaulter's Gun Belt (AGB)", link: "https://www.first-spear.com/assaulter-s-gun-belt-agb-/" },
      { name: "Sensitive Site Exploitation (SSE) Pouch", link: "https://www.first-spear.com/sensitive-site-exploitation-sse-pouch/" },
      { name: "Tactical Hand-Warmer", link: "https://www.first-spear.com/tactical-hand-warmer/" },
      { name: "ESS CROSSBOW ONE SUNGLASSES", link: "https://www.uspatriottactical.com/ess-crossbow-one-sunglasses?gad_source=1&gclid=EAIaIQobChMIpJ6uwtmMgwMVhk9HAR3EvQgWEAAYAiAAEgL7L_D_BwE" },
      { name: "OPS-CORE FAST SF HIGH CUT HELMET SYSTEM", link: "https://shop.gentexcorp.com/ops-core-fast-sf-high-cut-helmet-system/" },
      { name: "STRETCH HELMET COVER BY FIRSTSPEAR", link: "https://shop.gentexcorp.com/stretch-helmet-cover-by-firstspear/" },
      { name: "OPS-CORE AMP COMMUNICATION HEADSET - CONNECTORIZED", link: "https://shop.gentexcorp.com/ops-core-amp-communication-headset-connectorized/" },
      { name: "OPS-CORE AMP U174 DOWNLEAD CABLE", link: "https://shop.gentexcorp.com/ops-core-amp-u174-downlead-cable/?fbt" },
      { name: "OPS-CORE AMP HELMET RAIL MOUNT KIT", link: "https://shop.gentexcorp.com/ops-core-amp-helmet-rail-mount-kit/?fbt" },
      { name: "OPS-CORE AMP CAMO SKIN SET", link: "https://shop.gentexcorp.com/ops-core-amp-camo-skin-set/?fbt" },
      { name: "OPS-CORE NFMI EARPLUGS", link: "https://shop.gentexcorp.com/ops-core-nfmi-earplugs/?fbt" },
      { name: "Half-Mask Respirator Intended for Ground Applications", link: "https://shop.gentexcorp.com/ops-core-sotr-and-sotr-lite/" },
      { name: "OPS-CORE SOTR O2 STRAPS HARNESS", link: "https://shop.gentexcorp.com/ops-core-sotr-o2-straps-harness/?fbt" },
      { name: "MECHANIX WEAR SPECIALTY VENT GLOVE", link: "https://www.uspatriottactical.com/mechanix-wear-specialty-vent-glove?PMWTNO=000000000003253&PMSRCH=" },
      { name: "G4 COMBAT SHIRT™", link: "https://www.cryeprecision.com/G4-Combat-Shirt?quantity=1&color=MultiCam" },
      { name: "G4 COMBAT PANT™", link: "https://www.cryeprecision.com/G4-Combat-Pant?quantity=1&color=MultiCam" },
      { name: "HEL-STAR 6 Gen III (Lead Jumper)", link: "https://www.hcctac.com/products/hel-star-6-gen-iii-lead-jumper?variant=34959917351066&utm_source=google&utm_medium=cpc&adpos=&scid=scplpshopify_US_3926082027554_34959917351066&sc_intid=shopify_US_3926082027554_34959917351066&gad_source=1&gclid=EAIaIQobChMI_cKN3d2MgwMVdV9HAR2R9ActEAQYBCABEgI8ZfD_BwE" },
      { name: "Lightweight Level 4 Plate (Model #1165)", link: "https://rmadefense.com/store/body-armor/level-iv-body-armor/lightweight-level-4-plates-model-1165/" },
      { name: "TNVC MOHAWK MK2 GEN. 2 ANVIS/AGW BATTERY PACK COUNTERWEIGHT", link: "https://tnvc.com/shop/tnvc-mohawk-mk2-gen2-anvisagw-battery-pack-counterweight/" },
      { name: "MOHOC Elite Ops 12MP Helmet Camera, 1080p HD Video MHDBK", link: "https://shop.opticsplanet.com/mohoc-elite-ops-12mp-helmet-camera-1080p-hd-video.html?_iv_code=2AS-CAC-MHDBK&gclsrc=aw.ds&gad_source=1&gclid=EAIaIQobChMI4OvVrOGMgwMVN1lHAR0S9wOEEAQYASABEgJDc_D_BwE" },
      { name: "HRT Zip-On Medium Pack", link: "https://hrttacticalgear.com/hrt-zip-on-medium-pack-back-panel-helmet/" },
    ],
  },
  {
    group: "Ethan - AOR1 - Custom Build Service",
    price: "227.94",
    build: "12",
    items: [
      { name: "Emerson CPC Tactical Vest Adjustable CAGE Plate Carrier Load-bearing MOLLE Vest", link: "https://www.ebay.com/itm/393993784587?hash=item5bbbdc0d0b%3Ag%3AYKQAAOSwOSpe4Z3S&amdata=enc%3AAQAIAAAA8ByfqLBcJqRd%2FNaxxTEB8bI%2FsXMyA3tAAJ4A3NDpyUBbbTWLWWUEV%2Fq9GNoJtW%2FpNvyP7E5svqjLLx55VhPXCGCGHTQPcgZnPqA0do6XvQa4wRDTQRBEu4v0yVOVwCMhjOq16fXpg4orYPv3Trgtq8KPr6gld5tZmOHj1wp8RBZ5%2BNh8qiTxf1sBKaHOlIi49qjTjaLlmxnOBhx9HrPv7CMfO8HuAO%2Bt5WhRmp0HFoH555iAcYuv3WmtSjjjjoEeLX86hBfkktZk62zVkgzCUkhSzEfatP8CxCO2rYuKjD%2FYtELBwwXdRzjB3PkYa%2FdMCw%3D%3D%7Ctkp%3ABk9SR5iCluSMYw&LH_BIN=1" },
      { name: "TMC Tactical Open-Top Triple Mag Pouch (AOR1) TMC2971-A1", link: "https://www.ebay.com/itm/143353076383?hash=item2160825a9f%3Ag%3ANp8AAOSwKe1dUBg%7E&amdata=enc%3AAQAIAAAA8CD2z19hWfPZHrF1wnHz2wDLLXlv1MVbalO1ngPWwI7sk9Wd6ybK%2F79UhZyznEQ1GKvBqyeu3Npxxu5Mq8wxL7jLpllGSRsDvbPqb6Og70Ix3wRD4Is9PuEWUxEmLYgDy%2BYO8z%2BWggLz160xSUz71c6UKky3uS1v06k8f2MdYtXB%2BzD0G94TeQZe8aJ2UzGZMsDrg2tnb6ZZ6n9uEJ41QV9jdtI01wqPbBOmW4oi%2F8a4psu828AaYdCTmMF3DGhHrcoHaKIZHnPNISQBz1aCYKyQpWMEiWy43wWYz8kBhUUb9D4JEeuzTurDUL8vI4xD4A%3D%3D%7Ctkp%3ABk9SR9immYWNYw&LH_BIN=1" },
    ],
  },
  {
    group: "Resident Evil 7",
    price: "?",
    build: "13",
    items: [
      { name: "Emerson Tactical JPC 2.0 Jump Plate Carrier Lightweight Vest Molle Body Armor", link: "https://www.ebay.com/itm/393996695991?var=662547845300&hash=item5bbc0879b7:g:P~sAAOSw2EtiODY2&amdata=enc%3AAQAIAAAA4Oy7X%2FD%2Fv0jq6DAy4TpqmQNytlkAifbAfDmSo%2FxVnkMdNmUO8%2FuVrhzfmSua9%2BejC%2BKYsObAt56BcEdHsalH1DqNeQoBPJQZxWb4SibQO%2FIii31lx36zGKhhudrwcmSfiV3qkIcoQCSbE30lZAnuKL8b1ckeHIenqbFKaZH%2FUGpWpAckSuUPUNU7d6kucH4Xm8q1w5biMjY0coYHTzkPbLwivo7H1mON87wZUV0kemvrS8C7Wp7tvrdKVrYuDGnfqsjzKp%2BrCrj64zcwR2wdHy%2F0MDwygPTvEywxq6BTMz%2F3%7Ctkp%3ABk9SR-C_6LaQYw" },
      { name: "Tactical Triple Open-Top Magazine Pouch Mag Pouch Nylon Cartridge Bag", link: "https://www.ebay.com/itm/265233899932?var=565105616568&hash=item3dc12c219c:g:IooAAOSwdJ9aIFhD&amdata=enc%3AAQAIAAAA8LZEXejDcOTa8xd2DA1oG%2Fw%2FnouMCB9%2BV8vTM57ol2q5u3EDADIpGZ7afErpg2aehkj5QNVeT1de9G9hgh6mj2jhRxPMI2hI0rzERFt4fCfDhrToGEoapH6LtG3EM7MOgIpBxOqFx%2BpaIZT97bH5D0h7kXL5Zebt6deOfAdelp68KLjXqobQjWNC8025ILmXntdeJyX%2F3Rtaw4RSkVvVZ2KfCIVlIbngok6%2BhpXZ7L%2BiRA3UN8h5iQLiNrdwbFm5v59tb7t%2FLvUpigPiWIj1BwOFpOGiG0Xszy4tWuzc%2BwHI3GAZJn5hX7o5VupRLV1OUQ%3D%3D%7Ctkp%3ABk9SR_zgsraQYw" },
      { name: "WoSporT Phone Carrier Plate Tactical Vest Chest Rig MOLLE Folding Navigation", link: "https://www.ebay.com/itm/266340018643?var=566162340877&hash=item3e031a29d3:g:eTEAAOSwkgtk0EUa&amdata=enc%3AAQAIAAAA8ByMEHLQ8jmgOEdT1xfxeelS9mhkb3GVMm7WS5Xv2ZWzl9H1JV4aUR1amkXMPDyc951LBAoq6N2w2aAUf3nn0L6uyX%2BLa7ARmirLrcFWWMJS6iXrI9%2BsxmZfhDPAx6zDPB36h77L4V%2FvRinpZv%2B2ZQhKeLRoIz7XFJpj53iwLCRLYdq8hbGt65SqAJo0d2TthhCE3bBEjwQVzC1BiD8XiJB9kulgVV71yDNkjxuGTQrVtxn%2FfJ0mjhENlSPp146g3rl1U5ZYBor%2BlB8z470ycH%2Ffj%2B%2FFYCHUBs%2BkDISRSku3xeWXEtUG9i6fZLbS8f4ATw%3D%3D%7Ctkp%3ABk9SR6yZlLeQYw" },
      { name: "ACETAC Sub-Abdominal Drop Dangler Pouch for Plate Carriers and Chest Rigs", link: "https://www.ebay.com/itm/134602048853?hash=item1f56e84555:g:tusAAOSwvIFkf7t3&amdata=enc%3AAQAIAAAA0C1gmGlQDEflzXG28Kwi9LhAmKeFHZLP2szZQCvNR2bwlMI8r%2FZHBbQ%2FNfuiyNxWuLTAU1rPg%2BCANJjknQdKOB4xNWUPa25S4E9c%2Bt2jAqCyuBDWTNy76780mC2cQH9NFT7jzMJEEC5zfKzuupYqJHXbMzTQM0IE%2F1vzQQkyLOiTefTxEJH10PT8%2B6%2FlF70c89CbnGjPaVK329dn4QW5Irq3PQqEOd5VvQJXNf%2Fx8EcUomcJbkxs4vNnuaaubhIQjYib5shaP%2FxC9e%2FTkpMQDUY%3D%7Ctkp%3ABk9SR6jYw8eRYw" },
      { name: "Radio Pouch Set for Side Tactical Lightweight Armor Plate Carrier Vest LACV", link: "https://www.ebay.com/itm/133109536543?hash=item1efdf2571f:g:~tkAAOSwlQddNiz~&amdata=enc%3AAQAIAAAA4L1RfGHmgfRUppt7sPNWhD128Ek5%2FQgwT5PaHgcHhb9YTnI28IIe1EEXJcJoRDuygHQxyNL1wWostNf00kVMKMH3oowEnDWi6vliQopsBsWHHzXeBdUV0%2BPq4DmVr53YnBaycmzRvvdMiZnXnXtuoTT69%2BTOI6UHX4whR7W8fM0UOsO8Fzx11OAEf5qzxBAynLBmtQaCdrJHyimaCoB27teclWCSBDmE7lxzxi2HnC9S%2F9lSVydsIlu%2BavvCWkAV%2FxYh7%2FhZZjHyM%2F90wUbU%2FxsRjscVUt%2F%2Bit1Ltdf9Ukv6%7Ctkp%3ABk9SR8LZiciRYw" },
    ]
  },
  {
    group: "CYAN_GRP_B",
    price: "201.78",
    build: "1829302",
    items: [
      { name: "Emerson Tactical JPC Jumpable Plate Carrier Body Armor Camouflage Vest MC Camo", link: "https://www.ebay.com/itm/254149867294?itmmeta=01HX2A9EYPE19V4GK9JVB5XSJ0&hash=item3b2c83371e:g:0S0AAOSw67tiVR3h&itmprp=enc%3AAQAJAAAA8AHMwVEKZANfS7iQnR%2FLw2kX7M%2FA92cyuia5%2BBWgtQs38QOBX%2FBy%2BEXaM8EFzXrOmh7JfNSYOgSOON%2B2b5kmOJ88kfY95KdNM%2FkaTUkcKx25jVs69BHFG5TRVOBfWQzWNaZPrw0gTUXSxv7MgedFYwhAWBA6kHZ%2BLFLYfAqwJY%2Fr%2FDcxajUVmL9D0Q3sZ1Lq3kQMfOI6CqvpivX85E0rP7pjtTUSF9xJp4B0ZJtt6ZgtpkWYxmrfh7AU6nW1RNKMMssOAujKlIUSsyynOGa%2FYliryq0hfzpiN%2BdEHvKDvg1FLqvoqqvdElruSX4ULfHtIA%3D%3D%7Ctkp%3ABk9SR7TvpcroYw" },
      { name: "KRYDEX Tactical Belt 1.75 in Heavy Rigger Duty Belt MOLLE Quick Release Hunting", link: "https://www.ebay.com/itm/394751527507?itmmeta=01HX2CX4BYPRB76BQR2TMCM7TV&hash=item5be9064a53:g:er0AAOSwO5xfUJP-&itmprp=enc%3AAQAJAAAA0N3K5rFMrHVHr%2FU4lfhuosDZUY7I46RM7iGk5SBlXicVuhVYDMZaE9frhYyp6UFqhKosnzTwXJmTIe6SUuE1hir4NXK6xn2hYco7AyjWnBbIQMGMw05jD10XBikcjv34ARbCJt6F63O6E58XP3FJE7UycG6Pz3cV4hq5WBvfrapB0aIsYaB2JuyXKg%2B%2FlM3cvJmrwh38zdRqbwW0xKCi2cXjsBMVeZNNCC1nIZmrm7RfBeev8x36FozV4g8i7sJDUKLPD1maaJdxcg7bQ0UAU60%3D%7Ctkp%3ABFBMjMb0zOhj" },
      { name: "Condor Triple M4 Mag Pouch", link: "https://tacticalgear.com/condor-triple-m4-mag-pouch-od-green#/22497/21169/1" },
      { name: "All-in-One Emergency Survival Kit: Compact, Durable Tactical Molle First Aid Pouch for Universal Use & Outdoor Preparedness", link: "https://www.temu.com/tactical-molle-first-aid-pouch-empty-small-medicine-ifak-for-duty-belt-compact-edc-kit-two-piece-system-emergency-survival-military-hunting-bag-g-601099518924193.html?_oak_mp_inf=EKGrs5qm1ogBGiAxNWNlMGNhNWIwNGI0ZmM4OTQzMjk2MGJkMTNjNWUxMSCD7JGm9DE%3D&top_gallery_url=https%3A%2F%2Fimg.kwcdn.com%2Fproduct%2Ffancy%2F29c0fbcd-27f9-4217-9451-4558aa91639f.jpg&spec_gallery_id=2006042976&refer_page_sn=10009&refer_source=0&freesia_scene=2&_oak_freesia_scene=2&_oak_rec_ext_1=MjE0OQ&_oak_gallery_order=1277368959%2C381360582%2C1921656834%2C121296828%2C2089188269&search_key=medkit%20pouch&refer_page_el_sn=200049&refer_page_name=search_result&refer_page_id=10009_1714845684802_83fgtlb7lu&_x_sessn_id=v3b6lg301p&_x_vst_scene=adg&_x_ads_sub_channel=search&_x_ads_channel=google&_x_ads_account=1213016319&_x_ads_set=19694142866&_x_ads_id=141345685810&_x_ads_creative_id=648389974220&_x_ns_source=g&_x_ns_gclid=Cj0KCQjwudexBhDKARIsAI-GWYW3OCT5x0_JtVCmNBvQcMpDh_g6yrYcyMIfxG6vJmJ1E9KmTiRsk7waAvJSEALw_wcB&_x_ns_placement=&_x_ns_match_type=e&_x_ns_ad_position=&_x_ns_product_id=&_x_ns_target=&_x_ns_devicemodel=&_x_ns_wbraid=Cj4KCAjwltKxBhACEi4AK4p7OAvfvK1at_hck7wThhR6r0QeEmINsADDPwf6qzEF38Q2Qcg9nkZMtk0uGgKouA&_x_ns_gbraid=0AAAAAo4mICGmoHeV6uYXRGV4nD5wVt5iw&_x_ns_keyword=temu&_x_ns_targetid=kwd-5681707004" },
      { name: "Molle Magazine Dump Pouch, Hunting Airsoft Ammo EDC Bag, Foldable Utility Mag Holster", link: "https://www.temu.com/molle-magazine-dump-pouch-hunting-military-airsoft-ammo-edc-bag-foldable-utility-mag-holster-g-601099536307834.html?_oak_mp_inf=EPqs2KKm1ogBGiBjMzA2YjUxYmU3MDQ0ODVjOTQxNzUwNjE5ZjhiNjg5MiC19Jqm9DE%3D&top_gallery_url=https%3A%2F%2Fimg.kwcdn.com%2Fproduct%2FFancyalgo%2FVirtualModelMatting%2F99c1d5bcaac9b2bc7089dba54794ed68.jpg&spec_gallery_id=2062202413&refer_page_sn=10009&refer_source=0&freesia_scene=2&_oak_freesia_scene=2&_oak_rec_ext_1=NDQ4&_oak_gallery_order=1346443179%2C1141740206%2C1151043207%2C813396467%2C414536659&search_key=dump%20pouch&refer_page_el_sn=200049&refer_page_name=search_result&refer_page_id=10009_1714845684802_83fgtlb7lu&_x_sessn_id=v3b6lg301p" },
      { name: "1pc Outdoor Sports Multifunctional Accessories Bag, MOLLE Walkie Talkie Bag", link: "https://www.temu.com/1pc-outdoor-sports-multifunctional-accessories-bag-molle-walkie-talkie-bag-g-601099552389599.html?_oak_mp_inf=EN%2Fzraqm1ogBGiBmYmFkYmUzZTczZDY0NGYzODVhY2VlZWMyYzAyZWNiMSDnosem9DE%3D&top_gallery_url=https%3A%2F%2Fimg.kwcdn.com%2Fproduct%2Ffancy%2F54f9f9c4-1e5f-47c6-a324-617f701dc2a8.jpg&spec_gallery_id=2099842404&refer_page_sn=10009&refer_source=0&freesia_scene=2&_oak_freesia_scene=2&_oak_rec_ext_1=NDk4&_oak_gallery_order=1125780131%2C1850929056%2C1489200436%2C268280454%2C723698844&search_key=radio%20pouch&refer_page_el_sn=200049&_x_sessn_id=v3b6lg301p&refer_page_name=search_result&refer_page_id=10009_1714846560164_r4k3pgjwls" },
    ]
 },
  {
    group: "Marksman Multicam Setup",
    price: "?",
    build: "12923023",
    items: [
      { name: "JPC 2.0 Swimmer Cut", link: "https://www.cryeprecision.com/JPC-2-0-Swimmer-Cut" },
      { name: "Raid LT 32 Pack", link: "https://www.mysteryranch.com/raid-lt-32-pack?quantity=1&color=Multicam" },
      { name: "Peltor Comtac VI", link: "https://www.atomicdefense.com/products/peltor-comtac-vi" },
      { name: "Fast SF High Cut Helmet", link: "https://shop.gentexcorp.com/ops-core-fast-sf-high-cut-helmet-system/" },
      { name: "Stretch Helmet Cover", link: "https://shop.gentexcorp.com/stretch-helmet-cover-by-firstspear/" },
      { name: "PVS 31a", link: "https://tnvc.com/shop/l3harris-bnvd-an-pvs-31a-2376fom/" },
      { name: "Triple M4 Mag Pouch", link: "https://condoroutdoor.com/collections/magazine-pouches/products/condor-triple-open-top-m4-m16-mag-pouch-multicam" },
      { name: "Speed Triple Mag Pouch", link: "https://www.blueforcegear.com/ten-speed-triple-m4-mag-pouch" },
      { name: "Dump Pouch", link: "https://www.trex-arms.com/store/t-rex-dump-pouch/" },
      { name: "G3 Combat Shirt", link: "https://www.cryeprecision.com/G3-Combat-Shirt" },
      { name: "G3 Comabt Pant", link: "https://www.cryeprecision.com/G3-Combat-Pant" },
      { name: "Alpha Belt", link: "https://www.bluealphabelts.com/product/battle-belt-lite/" },
      { name: "General Purpose Pouch", link: "https://www.velsyst.com/products/jungle-general-purpose-pouch" },
      { name: "Adjustable Radio Pouch", link: "https://www.cryeprecision.com/AirLite-Configurable-Radio-Pouch?quantity=1&size=One%2520Size&color=MultiCam" },
      { name: "Bayofeng Foldable Antenna", link: "https://baofengtech.com/product/na-f30/" },
      { name: "Blowout Medkit", link: "https://lbtinc.com/products/9022b-t" },
      { name: "Drop-Leg Holster", link: "https://safariland.com/products/model-6004-usn-sls-low-signature-tactical-holster-34836" },
      { name: "Level IV+ Ballistic Front & Back Plate", link: "https://uarmprotection.com/product/icw4s-level-iv-silicon-carbide/?attribute_pa_size=s" },
      { name: "Level III Ballistic Side Plate", link: "https://uarmprotection.com/product/sa3usp-level-iii-uhmwpe-side/?attribute_pa_size=6x6" },
      { name: "Side Plate Pouches", link: "https://www.cryeprecision.com/JPC-Side-Plate-Pouch-Set" },
      { name: "IR Strobe", link: "https://www.sandsprecision.com/illum-marking/manta-strobe-156.html.html" },
      { name: "Phone Mount (Admin)", link: "https://juggernautcase.com/products/armor.mount-plate-carrier-pals/molle-phone-.html" },
    ]
  }
  // {
  //   group: "Group 2",
  //   price: "100",
  //   build: "10",
  //   items: [
  //     { name: "Item 2.1", link: "https://link3.com" },
  //     { name: "Item 2.2", link: "https://link4.com" },
  //   ],
  // },
];

export default data;
